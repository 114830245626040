import Mailchimp from '@mailchimp/mailchimp_transactional';

import { PRODUCTION_WEBSITE_URL } from '@data/constants';
import {
	NewsletterSignUpLocation,
	SupabaseMembershipGiftRecord,
	SupabaseProductRecordWithPrices,
} from '@interfaces';

import {
	convertPostgresTimestamptzToUTCForMailchimp,
	formatDateNumeric,
} from './date';
import { handleError } from './handleError';
import { formatPrice } from './price';

const mailchimpTransactionalClient = Mailchimp(
	process.env.MAILCHIMP_TRANSACTIONAL_EMAIL_SMTP_PASSWORD ?? '',
);

interface GetMailchimpUrlParams {
	email?: string;
	location?: NewsletterSignUpLocation;
	mailchimpFormLocationFieldTag?: string;
	mailchimpListId: string;
	mailchimpTags?: string;
}

export const getMailchimpUrl = ({
	email,
	location,
	mailchimpFormLocationFieldTag,
	mailchimpListId,
	mailchimpTags,
}: GetMailchimpUrlParams) => {
	const mailchimpServer = process.env.NEXT_PUBLIC_MAILCHIMP_SERVER ?? '';
	const mailchimpUserId = process.env.NEXT_PUBLIC_MAILCHIMP_USER_ID ?? '';
	const mailchimpUserName = process.env.NEXT_PUBLIC_MAILCHIMP_USER_NAME ?? '';

	const actionUrlObject = new URL(
		`https://${mailchimpUserName}.${mailchimpServer}.list-manage.com/subscribe/post?u=${mailchimpUserId}&id=${mailchimpListId}`,
	);

	if (mailchimpFormLocationFieldTag && location) {
		actionUrlObject.searchParams.append(
			mailchimpFormLocationFieldTag,
			location,
		);
	}

	if (mailchimpTags) {
		actionUrlObject.searchParams.append('tags', mailchimpTags);
	}

	if (email) {
		actionUrlObject.searchParams.append('EMAIL', email);
	}

	return actionUrlObject.toString();
};

export type MailchimpGiftTemplate =
	| 'thank-you-for-your-purchase'
	| 'you-received-a-membership-gift'
	| 'your-membership-gift-was-delivered';

interface SendMailchimpGiftEmailParams {
	templateName: MailchimpGiftTemplate;
	recipientEmail: string;
	membershipGift: SupabaseMembershipGiftRecord;
	product: SupabaseProductRecordWithPrices;
	sendNow?: boolean;
}

export const sendMailchimpGiftEmail = async ({
	templateName,
	recipientEmail,
	membershipGift,
	product,
	sendNow = false,
}: SendMailchimpGiftEmailParams) => {
	const {
		months,
		id: giftId,
		purchaser_name: purchaserName,
		recipient_name: recipientName,
		delivery_time: deliveryTime,
		delivery_note: deliveryNote,
		recipient_email: giftRecipientEmail,
		created_at: createdAt,
	} = membershipGift;

	try {
		const template = await mailchimpTransactionalClient.templates.info({
			name: templateName,
		});

		if (template instanceof Error) {
			handleError(template);
			return;
		}

		if (!template) {
			handleError('Mailchimp template not found');
			return;
		}

		const templateContent = [
			{
				name: 'purchaser_name',
				content: purchaserName,
			},
			{
				name: 'recipient_name',
				content: recipientName,
			},
			{
				name: 'recipient_email',
				content: giftRecipientEmail,
			},
			{
				name: 'product_name',
				content: product.name,
			},
			{
				name: 'duration',
				content: months === 12 ? '1 year' : '6 months',
			},
			{
				name: 'created_at',
				content: formatDateNumeric(createdAt),
			},
			{
				name: 'delivery_date',
				content: formatDateNumeric(deliveryTime),
			},
			{
				name: 'delivery_note',
				content: deliveryNote && deliveryNote.length > 0 ? deliveryNote : '-',
			},
			{
				name: 'gift_id',
				content: giftId,
			},
			{
				name: 'claim_gift_button',
				content: `<a href="${`${PRODUCTION_WEBSITE_URL}/membership/${product.slug}/checkout?gift=${giftId}`}">Claim your gift</a>`,
			},
		];

		const productPrice = product?.prices?.[0];

		if (
			productPrice &&
			productPrice?.unit_amount &&
			productPrice?.interval_count
		) {
			templateContent.push({
				name: 'product_price',
				content: formatPrice({
					cents:
						(productPrice.unit_amount / productPrice.interval_count) * months,
					maximumFractionDigits: 0,
					minimumFractionDigits: 0,
				}),
			});
		}

		const conditionalTemplateContent = [
			{
				name: 'PRODUCT_SLUG',
				content: product.slug,
			},
		];

		if (deliveryNote) {
			conditionalTemplateContent.push({
				name: 'DELIVERY_NOTE',
				content: deliveryNote,
			});
		}

		const email = await mailchimpTransactionalClient.messages.sendTemplate({
			template_name: template.slug,
			template_content: templateContent,
			send_at:
				deliveryTime && !sendNow
					? convertPostgresTimestamptzToUTCForMailchimp(deliveryTime)
					: undefined,
			message: {
				subject: template.subject || template.name,
				from_email: process.env.MAILCHIMP_EVENT_EMAIL_REPLY_TO,
				from_name: process.env.MAILCHIMP_EVENT_EMAIL_FROM_NAME,
				global_merge_vars: conditionalTemplateContent,
				to: [
					{
						email: recipientEmail,
						type: 'to',
					},
				],
			},
		});

		if (email instanceof Error) {
			handleError(email);
		}
	} catch (templateError) {
		handleError(templateError);
	}
};
