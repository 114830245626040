import { fromZonedTime, toZonedTime } from 'date-fns-tz';

import {
	MEMBERSHIP_GIFT_DELIVERY_TIME_UTC,
	TIMEZONE_NAME,
} from '@data/constants';
import { SupabaseEventRecord } from '@interfaces';

export const formatDateShort = (date: string): string =>
	new Date(date).toLocaleDateString('en-US', {
		weekday: 'short',
		month: 'short',
		day: 'numeric',
		timeZone: TIMEZONE_NAME,
	});

export const formatDateNumeric = (date: string) =>
	new Date(date).toLocaleString('en-US', {
		month: '2-digit',
		day: '2-digit',
		year: 'numeric',
		timeZone: TIMEZONE_NAME,
	});

export const formatDateLong = (date: string): string => {
	const parsedDate = new Date(date);
	const weekday = parsedDate.toLocaleDateString('en-US', {
		weekday: 'long',
		timeZone: TIMEZONE_NAME,
	});
	const day = parsedDate.toLocaleDateString('en-US', {
		day: 'numeric',
		timeZone: TIMEZONE_NAME,
	});
	const month = parsedDate.toLocaleDateString('en-US', {
		month: 'long',
		timeZone: TIMEZONE_NAME,
	});
	const year = parsedDate.toLocaleDateString('en-US', {
		year: 'numeric',
		timeZone: TIMEZONE_NAME,
	});
	return `${weekday} ${month} ${day}, ${year}`;
};

export const formatTime = (date: string): string =>
	new Date(date).toLocaleTimeString('en-US', {
		timeZone: TIMEZONE_NAME,
		hour: 'numeric',
	});

export const formatDateHuman = (dateString: string) => {
	const compareDate = formatDateShort(dateString);
	const today = new Date();
	const tomorrow = new Date();
	tomorrow.setDate(today.getDate() + 1);
	if (formatDateShort(today.toUTCString()) === compareDate) {
		return 'Today';
	}
	if (formatDateShort(tomorrow.toUTCString()) === compareDate) {
		return 'Tomorrow';
	}
	return compareDate;
};

export const getWeekStartAndEndDate = (offsetByNumberOfWeeks = 0) => {
	const startDate = new Date();
	const endDate = new Date();

	// Treat monday as the first day
	const startDay = startDate.getDate() - startDate.getDay() + 1;
	const endDay = startDay + 6;

	startDate.setDate(startDay);
	endDate.setDate(endDay);

	if (offsetByNumberOfWeeks > 0) {
		startDate.setDate(startDate.getDate() + offsetByNumberOfWeeks * 7);
		endDate.setDate(endDate.getDate() + offsetByNumberOfWeeks * 7);
	}

	return {
		startDate,
		endDate,
	};
};

export const getWeekStartAndEndDateFromToday = () => {
	const startDate = new Date();
	const endDate = new Date();

	const startDay = startDate.getDate();
	const endDay = startDay + 7;

	startDate.setDate(startDay);
	endDate.setDate(endDay);

	return {
		startDate,
		endDate,
	};
};

const prepareForFilter = (startDate: Date, endDate: Date) => {
	// A day later in order to include the last day
	endDate.setDate(endDate.getDate() + 1);

	return [startDate, endDate].map((dateObject) => {
		const [dateString] = dateObject.toISOString().split('T');

		// Midnight for defined timezone in UTC
		const utcDate = fromZonedTime(`${dateString} 00:00:00`, TIMEZONE_NAME);

		return new Date(utcDate).toISOString();
	});
};

export const getDaysInMonth = (year: number, month: number) => {
	return new Date(year, month, 0).getDate();
};

export const getMonthStartAndEndDate = (offsetByNumberOfMonth = 0) => {
	const startDate = new Date();
	const endDate = new Date();

	if (offsetByNumberOfMonth > 0) {
		startDate.setMonth(startDate.getMonth() + offsetByNumberOfMonth);
		endDate.setMonth(endDate.getMonth() + offsetByNumberOfMonth);
	}

	const endDay = getDaysInMonth(
		startDate.getFullYear(),
		startDate.getMonth() + 1,
	);

	startDate.setDate(1);
	endDate.setDate(endDay);

	return {
		startDate,
		endDate,
	};
};

export const getWeekDateRange = (offsetByNumberOfWeeks = 0) => {
	const { startDate, endDate } = getWeekStartAndEndDate(offsetByNumberOfWeeks);
	return prepareForFilter(startDate, endDate);
};

export const getWeekDateRangeFromToday = () => {
	const { startDate, endDate } = getWeekStartAndEndDateFromToday();
	return prepareForFilter(startDate, endDate);
};

export const getMonthDateRange = (offsetByNumberOfMonths = 0) => {
	const { startDate, endDate } = getMonthStartAndEndDate(
		offsetByNumberOfMonths,
	);
	return prepareForFilter(startDate, endDate);
};

export const getTimeSince = (date: string) => {
	const dateObject = new Date(date);
	const now = Date.now();

	const millisecondsSince = now - dateObject.getTime();

	const hours = millisecondsSince / (60 * 60 * 1000);
	const minutes = millisecondsSince / (60 * 1000);

	return {
		hours,
		minutes,
	};
};

export const isEventInRange = (
	event: SupabaseEventRecord,
	rangeInHours: number,
) => {
	const now = Date.now();
	const start = new Date(event.data.date).getTime();
	const end = new Date(event.data.date_end).getTime();

	if (now > end) {
		// event has ended
		return false;
	}

	const millisecondsPerHour = 60 * 60 * 1000;
	const differenceInMilliseconds = start - now;
	const differenceInHours = differenceInMilliseconds / millisecondsPerHour;

	if (differenceInHours > rangeInHours) {
		// event does not start within the range
		return false;
	}

	return true;
};

export function getCurrentDate() {
	const today = toZonedTime(new Date(), TIMEZONE_NAME);

	const year = today.getFullYear();
	const month = (today.getMonth() + 1).toString().padStart(2, '0');
	const day = today.getDate().toString().padStart(2, '0');

	return `${year}-${month}-${day}`;
}

export function getOneMonthAgoDate() {
	const today = toZonedTime(new Date(), TIMEZONE_NAME);
	today.setMonth(today.getMonth() - 1);

	const year = today.getFullYear();
	const month = (today.getMonth() + 1).toString().padStart(2, '0');
	const day = today.getDate().toString().padStart(2, '0');

	return `${year}-${month}-${day}`;
}

export function convertPostgresTimestamptzToUTCForMailchimp(
	pgTimestamp: string,
) {
	const date = new Date(pgTimestamp);
	return date.toISOString().substring(0, 19).replace('T', ' ');
}

export function isDateToday(date: Date) {
	const today = new Date();
	const dateToCheck = new Date(date.valueOf());
	return today.setHours(0, 0, 0, 0) === dateToCheck.setHours(0, 0, 0, 0);
}

export const getGiftMembershipDeliveryTime = (
	year: number,
	month: number,
	day: number,
) =>
	new Date(
		Date.UTC(
			year,
			month - 1,
			day,
			...MEMBERSHIP_GIFT_DELIVERY_TIME_UTC.split(':').map(Number),
		),
	);
