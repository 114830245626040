// convert a string into an object with first and last name properties
export const splitFullName = (
	fullName: string,
): { firstName: string; lastName: string | undefined } => {
	const [firstName, lastName] = fullName.split(/\s+(.*)/);

	return {
		firstName,
		lastName,
	};
};

// capitalize the first letter of a string
export const capitalize = (string: string) =>
	string.charAt(0).toUpperCase() + string.slice(1);

export const truncateString = (string: string, characters: number) =>
	string.length > characters ? `${string.substring(0, characters)}...` : string;
